import PrivateRoute from '@app/routes/utils/private-route';
import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

const Devices = lazy(() => import('./devices'));

export default function SettingsRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/settings"
        component={Devices}
      />
    </Switch>
  );
}
