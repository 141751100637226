import PrivateRoute from '@app/routes/utils/private-route';
import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

const Wallet = lazy(() => import('./wallet'));

export default function WalletRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/wallet"
        component={Wallet}
      />
    </Switch>
  );
}
