import { $api } from '@app/api';
import { createEffect } from 'effector/compat';
import { resettableDomain } from './Auth';

export const fetchUserProfileFx = createEffect(() => $api.get('user/profile').then(({ data }) => data));

export const $user = resettableDomain.createStore({
  username: null,
  role: null,
  customer: null,
  email: null,
  userLocation: null,
})
  .on(fetchUserProfileFx.doneData, (_, payload) => payload);
