import {
  createEvent,
  createStore,
} from 'effector-root';

export const displayTimer = createEvent();
export const hideTimer = createEvent();

export const $timerVisible = createStore(false)
  .on(displayTimer, () => true)
  .reset(hideTimer);
