import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.scss';

export default function Loader({ error }) {
  return (
    <div className={styles.loader__wrapper}>
      <div className={styles.loader}>&nbsp;</div>
      {error && <p className={styles.loader__error}>{error}</p>}
    </div>
  );
}

Loader.propTypes = {
  error: PropTypes.string,
};

Loader.defaultProps = {
  error: null,
};
