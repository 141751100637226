import {
  guard,
  forward,
  createEffect,
  combine,
} from 'effector';
import { createGate } from 'effector-react';
import { fetchUserProfileFx } from './User';
import { fetchCustomerProfileFx } from './Customer';
import { resettableDomain, logout } from './Auth';

export const privateRouteGate = createGate();
export const logoutDelayFx = createEffect(() => new Promise((resolve) => {
  setTimeout(() => resolve(), 3000);
}));

export const $userProfileFetched = resettableDomain.createStore(false)
  .on(fetchUserProfileFx.doneData, () => true);

export const $customerProfileFetched = resettableDomain.createStore(false)
  .on(fetchCustomerProfileFx.doneData, () => true);

export const $profilesFetched = combine(
  $userProfileFetched, $customerProfileFetched,
  (first, second) => first && second,
);

export const $error = resettableDomain.createStore(null)
  .on(fetchUserProfileFx.failData, () => 'Failed to fetch user profile')
  .on(fetchCustomerProfileFx.failData, () => 'Failed to fetch customer profile');

guard({
  clock: privateRouteGate.open,
  source: $profilesFetched,
  filter: (fetched) => !fetched,
  target: [fetchUserProfileFx, fetchCustomerProfileFx],
});

forward({
  from: $error,
  to: logoutDelayFx,
});

forward({
  from: logoutDelayFx.done,
  to: logout,
});
