import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../utils/private-route';

const Logger = lazy(() => import('./logger'));
const EmployeeAccess = lazy(() => import('./employee-access'));
const Configurations = lazy(() => import('./configurations'));

export default function ApplicationAdministrationRoutes() {
  return (
    <Switch>
      <PrivateRoute path="/application-administration/configurations" component={Configurations} />
      <PrivateRoute path="/application-administration/logger" component={Logger} />
      <PrivateRoute path="/application-administration/employee-access" component={EmployeeAccess} />
    </Switch>
  );
}
