import '@shared/css/global.styles.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from '@app/routes/utils/history';
import Routes from './routes';

ReactDOM.render(
  <React.StrictMode>
    <Router basename="/" history={history}>
      <Routes />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
