import localConfig from './local';
import devConfig from './dev';
import diveConfig from './dive';
import prodConfig from './prod';

export default new Map([
  ['local', localConfig],
  ['dev', devConfig],
  ['dive', diveConfig],
  ['prod', prodConfig],
]);
