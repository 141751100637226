import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../utils/private-route';

const AutoPayAuthorizations = lazy(() => import('./autopay-authorizations'));
const ViewAuthorizations = lazy(() => import('./view-authorizations'));
const EditAuthorizations = lazy(() => import('./edit-authorizations'));

export default function AuthRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/autopay-authorizations"
        component={AutoPayAuthorizations}
      />
      <PrivateRoute
        path="/view-authorizations"
        component={ViewAuthorizations}
      />
      <PrivateRoute
        path="/edit-authorizations"
        component={EditAuthorizations}
      />
    </Switch>
  );
}
