import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../utils/private-route';

const EmailTemplate = lazy(() => import('./email-template'));

export default function EmailTemplateRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/email-template"
        roles={['Administrator']}
        component={EmailTemplate}
      />
    </Switch>
  );
}
