import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../utils/private-route';

const UserAccess = lazy(() => import('./user-access'));

export default function CustomerAdministrationRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/customer-administration/user-access"
        roles={['Customer Admin', 'Customer Sales Rep', 'Financial Services', 'Administrator']}
        component={UserAccess}
      />
    </Switch>
  );
}
