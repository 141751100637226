import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import * as logoutInterval from '@shared/helpers/logout-interval.helper';
// eslint-disable-next-line import/no-cycle,import/named
import { logout } from '@shared/models/Auth';
// eslint-disable-next-line import/no-cycle
import configProps from './config-headers';
import configs from '../configs';

export const $api = axios.create({
  withCredentials: true,
  baseURL: configs.get(process.env.REACT_APP_ENV).baseURL,
  timeout: 30000,
});

export const $refreshApi = axios.create({
  withCredentials: true,
  baseURL: configs.get(process.env.REACT_APP_ENV).baseURL,
});

const errorInterceptor = (error) => {
  if (error.response.status === 401) {
    logoutInterval.clearTimer();
    logout();
    localStorage.setItem('access_token', null);
    localStorage.setItem('impersonated_token', null);
    window.location = '/';
  }
  return Promise.reject(error);
};

$api.interceptors.response.use((config) => config, errorInterceptor);
$api.interceptors.request.use((config) => {
  if (config.url !== 'impersonate/remove'
  && config.url !== 'auth/refresh'
  && config.url !== 'impersonate/refresh') {
    logoutInterval.setLogoutInterval();
  }
  logoutInterval.askToRefreshToken();
  return configProps(config);
});
