import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../utils/private-route';

const AccountSummary = lazy(() => import('./account-summary'));
const PaymentDetails = lazy(() => import('./payment-details'));

export default function AccountSummaryRoutes() {
  return (
    <Switch>
      <PrivateRoute path="/account-summary" component={AccountSummary} />
      <PrivateRoute path="/payment-details/:paymentNumber" component={PaymentDetails} />
    </Switch>
  );
}
