// eslint-disable-next-line import/no-cycle
import { logout } from '@shared/models/Auth';
import {
  displayTimer,
} from '@shared/components/CommonUI/LogoutTimer/LogoutTimer.model';
// eslint-disable-next-line import/no-cycle
import refreshAccessToken from '@shared/helpers/refreshToken.helper';

export const IMPLEMENT_AUTO_LOGOUT_INTERVAL = 900000; // 15 minutes in milliseconds
let logoutTimer;
let endSessionTimer;
let refreshTimer;

export const askToRefreshToken = () => {
  clearTimeout(refreshTimer);
  refreshTimer = setTimeout(() => {
    if ((localStorage.getItem('access_token') !== 'null')) refreshAccessToken();
  }, IMPLEMENT_AUTO_LOGOUT_INTERVAL / 2);
};

export const setLogoutInterval = () => {
  clearTimeout(logoutTimer);
  logoutTimer = setTimeout(() => logout(), IMPLEMENT_AUTO_LOGOUT_INTERVAL);
  clearTimeout(endSessionTimer);
  endSessionTimer = setTimeout(() => {
    displayTimer();
  }, IMPLEMENT_AUTO_LOGOUT_INTERVAL - 60000);
};

export const clearTimer = () => {
  clearTimeout(logoutTimer);
  clearTimeout(endSessionTimer);
  clearTimeout(refreshTimer);
};
