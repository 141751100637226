import {
  guard,
  createEvent,
  createEffect,
  createDomain,
} from 'effector';
import { persist } from 'effector-storage/local';
// eslint-disable-next-line import/no-cycle
import { $api } from '@app/api';
// eslint-disable-next-line import/no-cycle
import { clearTimer } from '@shared/helpers/logout-interval.helper';
import { sample } from 'effector-root';

export const resettableDomain = createDomain();
export const logout = createEvent();
export const logoutFx = createEffect(async () => $api
  .post('auth/logout')
  .then(({ data }) => data));

export const signInFx = createEffect(async ({
  userName,
  password,
  geoLocation,
  activationCode,
}) => $api
  .post('auth/signin', {
    userName,
    password,
    geoLocation,
    activationCode,
  })
  .then(({ data }) => data));

export const refreshAT = createEffect(async () => $api
  .post('auth/refresh')
  .then(({ data }) => data));

logout.watch(() => clearTimer());

resettableDomain.onCreateStore((store) => {
  store.reset(logout);
});

export const $token = resettableDomain.createStore(null)
  .on(signInFx.doneData, (_, { token }) => token)
  .on(refreshAT.doneData, (_, { token }) => token);

export const setTokenToHeaders = (token) => new Promise(() => {
  $api.defaults.headers.common['x-access-token'] = token;
});

export const setToken = createEffect((token) => setTokenToHeaders(token));
export const setRefreshedAT = createEffect((token) => setTokenToHeaders(token));

export const $isAuthorized = $token.map(Boolean);

guard({
  source: $token,
  filter: Boolean,
  target: setToken,
});

persist({
  store: $token,
  key: 'access_token',
});

sample({
  clock: logout,
  target: logoutFx,
});
