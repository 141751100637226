import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../utils/private-route';

const AutopayPayments = lazy(() => import('./autopay-payments'));
const Payments = lazy(() => import('./payments'));
const CCExpiration = lazy(() => import('./cc-expiration'));

export default function AuthRoutes() {
  return (
    <Switch>
      <PrivateRoute path="/reports/autopay-payments" component={AutopayPayments} />
      <PrivateRoute path="/reports/payments" component={Payments} />
      <PrivateRoute path="/reports/cc-expiration" component={CCExpiration} />
    </Switch>
  );
}
