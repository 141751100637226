// eslint-disable-next-line import/named,import/no-cycle
import { setTokenToHeaders } from '@shared/models/Auth';

export default (config) => {
  const updatedConfig = config;
  const accessToken = localStorage.getItem('access_token')?.slice(1, -1);
  const impersonatedToken = localStorage.getItem('impersonated_token')?.slice(1, -1);
  if (config.url === 'user/profile') {
    updatedConfig.headers.common['x-access-token'] = accessToken;
  }
  if (!config.headers.common['x-access-token']) {
    if (impersonatedToken !== 'ul' && impersonatedToken) {
      updatedConfig.headers.common['x-access-token'] = impersonatedToken;
      setTokenToHeaders(impersonatedToken).then(() => console.log('change token'));
    } else if (accessToken !== 'ul' && accessToken) {
      updatedConfig.headers.common['x-access-token'] = accessToken;
      setTokenToHeaders(accessToken).then(() => console.log('change token'));
    }
  }
  updatedConfig.headers.common['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains';
  updatedConfig.headers.common['Content-Security-Policy'] = "default-src 'self'";
  updatedConfig.headers.common['X-Frame-Options'] = 'SAMEORIGIN';
  updatedConfig.headers.common['X-Content-Type-Options'] = 'nosniff';
  updatedConfig.headers.common['Referrer-Policy'] = 'origin';
  updatedConfig.headers.common['Permissions-Policy'] = "microphone 'none'; geolocation 'none'";
  return updatedConfig;
};
