import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import useRoles from '@shared/hooks/useRoles';
import { useGate, useStore } from 'effector-react';
import Loader from '@shared/components/CommonUI/Loader';
import { $isAuthorized } from '@shared/models/Auth';
import {
  $error,
  $profilesFetched,
  privateRouteGate,
} from '@shared/models/PrivateRoute';

const PrivateRoute = ({ component: Component, roles, ...props }) => {
  useGate(privateRouteGate);
  const isAuth = useStore($isAuthorized);
  const profilesFetched = useStore($profilesFetched);
  const error = useStore($error);
  const roleAllowed = useRoles(roles);

  return (
    <Route
      {...props}
      render={({ location }) => {
        if (!isAuth) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        if (profilesFetched) {
          if (!roleAllowed) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            );
          }

          return <Component />;
        }

        return <Loader error={error} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.oneOfType([PropTypes.array]),
};

PrivateRoute.defaultProps = {
  roles: [],
};

export default PrivateRoute;
