import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../utils/private-route';

const SelectTransactions = lazy(() => import('./select-transactions'));
const ReviewAndPay = lazy(() => import('./review-and-pay'));

export default function PaymentsRoutes() {
  return (
    <Switch>
      <PrivateRoute path="/select-transactions" component={SelectTransactions} />
      <PrivateRoute path="/review-and-pay" component={ReviewAndPay} />
    </Switch>
  );
}
