import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../utils/private-route';

const CustomerProfile = lazy(() => import('./customer-profile'));

export default function ProfileRoutes() {
  return (
    <Switch>
      <PrivateRoute path="/customer-profile" component={CustomerProfile} />
    </Switch>
  );
}
