// eslint-disable-next-line import/no-cycle,import/named
import { $refreshApi } from '@app/api';
// eslint-disable-next-line import/no-cycle
import { setRefreshedAT } from '@shared/models/Auth';

export default () => {
  const isImpersonated = (localStorage.getItem('impersonated_token') !== 'null');
  if (isImpersonated) {
    $refreshApi
      .post('impersonate/refresh')
      .then(({ data }) => {
        setRefreshedAT(data.token);
        localStorage.setItem('impersonated_token', `"${data.token}"`);
      });
  } else {
    $refreshApi
      .post('auth/refresh')
      .then(({ data }) => {
        setRefreshedAT(data.token);
        localStorage.setItem('access_token', `"${data.token}"`);
      });
  }
};
