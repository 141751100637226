import { createEffect, createEvent, forward } from 'effector';
import { $api } from '@app/api';
import { resettableDomain } from '@shared/models/Auth';
import { createGate } from 'effector-react';

export const fetchCustomerProfileFx = createEffect(() => $api.get('customer/get-profile').then(({ data }) => data));

export const resetCustomer = createEvent();
export const $customer = resettableDomain.createStore({})
  .on(fetchCustomerProfileFx.doneData, (customer, payload) => payload)
  .reset(resetCustomer);

export const CustomerGate = createGate();

forward({
  from: CustomerGate.open,
  to: fetchCustomerProfileFx,
});

export const $customerAgreements = $customer.map((s) => s && [
  {
    label: 'Terms and Conditions',
    date: s.TCAgreement && new Date(s.TCAgreement),
  },
  {
    label: 'Terms and Conditions Payment',
    date: s.TCPayment && new Date(s.TCPayment),
  },
  {
    label: 'Terms and Conditions Wallet',
    date: s.TCCustomerWallet && new Date(s.TCCustomerWallet),
  },
  {
    label: 'Terms and Conditions EXpress',
    date: s.TCExpressPayment && new Date(s.TCExpressPayment),
  },
  {
    label: 'Terms and Conditions AutoPay',
    date: s.TCAutoPayContract && new Date(s.TCAutoPayContract),
  },
]);
