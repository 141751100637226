import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const Login = lazy(() => import('./login'));
const CreateAccount = lazy(() => import('./create-account'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const ForgotUsername = lazy(() => import('./forgot-username'));
const ResetPassword = lazy(() => import('./reset-password'));
const RegistrationCode = lazy(() => import('./registration-code/RegistrationCode'));
const Revoke = lazy(() => import('./revoke'));

export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/forgot-username" component={ForgotUsername} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/password/reset/:username/:token" component={ResetPassword} />
      <Route path="/create-account" component={CreateAccount} />
      <Route path="/request-email" component={RegistrationCode} />
      <Route path="/revokePage/:token" component={Revoke} />
    </Switch>
  );
}
