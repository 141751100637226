import React, { Suspense } from 'react';
import Loader from '@shared/components/CommonUI/Loader';
import AuthRoutes from './auth';
import PaymentsRoutes from './payments';
import AutoPayRoutes from './autopay';
import ReportsRoutes from './reports';
import ProfileRoutes from './profile';
import AccountSummaryRoutes from './account-summary';
import ApplicationAdministrationRoutes from './application-administration';
import CustomerAdministrationRoutes from './customer-administration';
import SettingsRoutes from './Settings';
import EmailTemplateRoutes from './email-template';
import WalletRoutes from './wallet';

function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <AuthRoutes />
      <AccountSummaryRoutes />
      <WalletRoutes />
      <PaymentsRoutes />
      <AutoPayRoutes />
      <ReportsRoutes />
      <ProfileRoutes />
      <CustomerAdministrationRoutes />
      <ApplicationAdministrationRoutes />
      <EmailTemplateRoutes />
      <SettingsRoutes />
    </Suspense>
  );
}

export default Routes;
